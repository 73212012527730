<template>
  <div class="about p-5" id="dementie-information">
    <h1 class="left">Gegevens over mijn regio</h1>
    <div class="row"></div>
    <div class="row down">
      <div class="col-sm-12">
        <card :cardId="'Percentage mensen met dementie t.o.v. volledige bevolking van 65 jaar en ouder over tijd'" :fontsize="15" :hovertext="'Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'" 
          :x="jaar_iden_ddt" :y="verschil_ddt_table.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Percentage'" 
          :tableHeight="selectedLevel === 'dementienetwerk' ? 400 : 400">
          <template slot="card-header">Percentage mensen met dementie t.o.v. volledige bevolking van 65 jaar en ouder over tijd</template>
          <div slot="card-body">
            <div class="row">
              <div class="col-sm-3 align">
                <div class="bigger">
                   Het aantal geïdentificeerde mensen met dementie in {{selectedYear}} in {{level_regio}} <b>{{selectedRegion}}</b> is <b>{{geidentificeerd[0]}}</b>. 
                </div>
              </div>
              <div class="col-sm-9">
                <div class="row">
                  <div class="col-sm-4">
                    <stacked-bar-chart-colors-numbers v-if="load.graphOne"
                    :title="'Aantal mensen met dementie'"
                    :ytitle="'aantal'"
                    :name1="'Aantal'"
                    :x1="netwerken_ddt"
                    :y1="geidentificeerd"
                    :marginBottom="70"
                    :marginTop="40"
                    :showlegend="false"
                    :niveau="niveau"
                    :tickangle="0"/>
                  </div>
                  <div class="col-sm-8">
                      <stacked-bar-chart-colors-multi v-if="load.graphSix"
                      :marginBottom="70"
                      :marginTop="40"
                      :title="'Percentage mensen met dementie over tijd'"
                      :name1="'Identificatie'"
                      :x1="netwerken_ddt"
                      :xx1="jaar_iden_ddt"
                      :y1="verschil_ddt"
                      :round="1"
                      :showlegend="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

<!--     <div class="row">
      <div class="col-sm-12">
        <card :cardId="'mijn-regio-2'" :fontsize="15" :hovertext="'De eerste identificatie is op basis van jaarniveau-data, dus of iemand in een bepaald jaar is gediagnosticeerd obv Wlz-zorg/indicatie, ziekenhuiszorg, medicatie of een andere bron. Wanneer dementie voor het eerst is gediagnosticeerd op basis van meerdere zorgvormen dan volgt de hiërarchie: medicatie>ziekenhuis>wlz> overig. Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'">
          <template slot="card-header">Eerste identificatie landelijk over tijd</template>
          <div slot="card-body">De grafiek over eerste diagnose van mensen met dementie wordt op korte termijn toegevoegd</div>
          <div slot="card-body">Deze grafieken tonen de eerste bron van identificatie voor de personen die zijn geïdentificeerd met dementie.
            <div class="row">
               <div class="col-sm-6">
                <stacked-bar-chart-colors v-if="load.graphNine" 
                  :title="'Aantal personen met dementie uitgesplitst naar eerste bron van identificatie'"
                  :ytitle="'Aantal'"
                  :xtitle="'Nederland'"
                  :names="['Wlz', 'Overig', 'Ziekenhuis', 'Medicatie']"
                  :x="[jaar_landelijk, jaar_landelijk, jaar_landelijk, jaar_landelijk]"
                  :y="[wlz_landelijk, overig_landelijk, ziekenhuis_landelijk, medicatie_landelijk]"
                  :showlegend="false"
                  :height="500"/>
              </div>
              <div class="col-sm-6">
                <stacked-bar-chart-colors v-if="load.graphTen" 
                  :title="'Personen met dementie uitgesplitst naar eerste bron van identificatie in percentages'"
                  :ytitle="'Percentage'"
                  :xtitle="'Nederland'"
                  :names="['Wlz', 'Overig', 'Ziekenhuis', 'Medicatie']"
                  :x="[jaar_landelijk, jaar_landelijk, jaar_landelijk, jaar_landelijk]"
                  :y="[wlz_landelijk_perc, overig_landelijk_perc, ziekenhuis_landelijk_perc, medicatie_landelijk_perc]"
                  :height="500"
                  :ticksuffix="'%'"/>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div> -->

    <div class="row">
      <div class="col-sm-12">
        <card-small :cardId="'Eerste identificatie landelijk over tijd'" :hovertext="'De eerste identificatie is op basis van jaarniveau-data, dus of iemand in een bepaald jaar is gediagnosticeerd obv Wlz-zorg/indicatie, ziekenhuiszorg, medicatie of een andere bron. Wanneer dementie voor het eerst is gediagnosticeerd op basis van meerdere zorgvormen dan volgt de hiërarchie: medicatie>ziekenhuis>wlz> overig. Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'">
            <template slot="card-header">Eerste identificatie landelijk over tijd</template>
            <div slot="card-body">
              De grafiek over eerste diagnose van mensen met dementie wordt op korte termijn toegevoegd
            </div>
          </card-small>
      </div>
    </div>
    
    <div class="row down">
      <div class="col-sm-12">
        <card :cardId="'Woonsituatie van mensen met dementie'" :fontsize="15" :hovertext="'Het aantal mensen dat in een zorginstelling woont komt niet overeen met het aantal mensen dat WLZ-zorg met verblijf ontvangt dat elders gerapporteerd wordt. Dat komt omdat voor deze grafiek gebruik wordt gemaakt van een andere bron: het Basisregister Personen (BRP). Pas als iemand een verhuizing naar een zorginstelling doorgeeft wordt hij/zij als bewoner van een zorginstelling aangemerkt. Daarnaast dient de locatie van de zorginstelling aangemerkt als zodanig aangemerkt te zijn in het BRP. Tot slot nemen we hier de huishoudenssamenstelling op 1 januari als uitgangspunt en wordt dus iedereen die gedurende het jaar naar een zorginstelling verhuist niet meegenomen in deze cijfers. Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'" 
          :x="zorgkantoorregionaam_huishouden" :y="jaar_huishouden" :z="percentage_huishouden.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Jaar'" :ztitle="'Percentage'" 
          :tableHeight="selectedLevel === 'dementienetwerk' ? 750 : 700">
          <template slot="card-header">Woonsituatie van mensen met dementie</template>
          <div slot="card-body">
            <div class="row">
              <div class="col-sm-3">
                <div class="bigger">
                     De grafieken hier rechts geven de samenstelling van de huishoudens van mensen met dementie in de regio weer. De linker grafiek toont de verdeling naar huishoudenssamenstelling voor {{level_regio}} {{selectedRegion}} in {{selectedYear}} in aantallen. In de rechter grafiek is deze verdeling over de tijd te zien en te vergelijken met de landelijke cijfers.
                     <br><br>
                     Let op dat het aantal geïdentificeerde mensen met dementie in <b>2019</b> en <b>2020</b> van voornamelijk ziekenhuizen nog niet compleet is, waardoor de Eenpersoons en Meerpersoons aantallen lager uitvallen.
                </div>
              </div>
              <div class="col-sm-9">
                <div class="row">
                  <div class="col-sm-4">
                    <stacked-bar-chart-colors-numbers v-if="load.graphOne"
                    :title="'Samenstelling woonsituatie'"
                    :ytitle="'aantal'"
                    :name3="'Eenpersoons'"
                    :x3="zorgkantoorregionaam_ep"
                    :y3="eenpersoons"
                    :name2="'Meerpersoons'"
                    :x2="zorgkantoorregionaam_mp"
                    :y2="meerpersoons"
                    :name1="'Zorginstelling'"
                    :x1="zorgkantoorregionaam_zi"
                    :y1="zorginstelling"
                    :marginBottom="70"
                    :marginTop="40"
                    :niveau="niveau"
                    :tickangle="0"/>
                  </div>
                  <div class="col-sm-8">
                      <stacked-bar-chart-colors-multi v-if="load.graphTwo"
                      :marginBottom="70"
                      :marginTop="40"
                      :title="'Samenstelling woonsituatie door de tijd'"
                      :name3="'Eenpersoons Huishouden'"
                      :x3="ddt_zorgkantoorregionaam_ep"
                      :xx3="ddt_jaar_ep"
                      :y3="ddt_eenpersoons"
                      :name2="'Meerpersoons Huishouden'"
                      :x2="ddt_zorgkantoorregionaam_mp"
                      :xx2="ddt_jaar_mp"
                      :y2="ddt_meerpersoons"
                      :name1="'Zorginstelling'"
                      :x1="ddt_zorgkantoorregionaam_zi"
                      :xx1="ddt_jaar_zi"
                      :y1="ddt_zorginstelling"
                      :showlegend="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row down">
      <div class="col-sm-12">
        <card :cardId="'Leeftijd mensen met dementie'" :fontsize="15" :hovertext="'Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'" 
          :x="zorgkantoorregionaam_leeftijd " :y="jaar_leeftijd " :z="categorie_leeftijd" :w="percentage_leeftijd.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Jaar'" :ztitle="'Categorie'"  :wtitle="'Percentage'" 
          :tableHeight="selectedLevel === 'dementienetwerk' ? 300 : 300">
          <template slot="card-header">Leeftijd mensen met dementie</template>
          <div slot="card-body">
            <div class="row">
              <div class="col-sm-3">
                <div class="bigger">
                  In de grafieken hiernaast is de leeftijdsverdeling van mensen met dementie in de regio te zien. De linker grafiek toont de verdeling voor {{level_regio}} {{selectedRegion}} in {{selectedYear}} in aantallen. De rechter grafiek toont de verdeling over de tijd en in vergelijking met de landelijke cijfers. 
                </div>
              </div>
              <div class="col-sm-9">
                <div class="row">
                  <div class="col-sm-4">
                          <stacked-bar-chart-colors-numbers v-if="load.graphThree" :name4="'65 jaar en jonger'"
                          :ytitle="'aantal'"
                          :title="'Leeftijdsverdeling regio'"
                          :x4="zorgkantoorregionaam_65"
                          :y4="leeftijd_65"
                          :name3="'66 - 79 jaar'"
                          :x3="zorgkantoorregionaam_66"
                          :y3="leeftijd_66"
                          :name2="'80 - 89 jaar'"
                          :x2="zorgkantoorregionaam_80"
                          :y2="leeftijd_80"
                          :name1="'90 jaar en ouder'"
                          :x1="zorgkantoorregionaam_90"
                          :y1="leeftijd_90"
                          :marginBottom="70"
                          :marginTop="40"
                          :niveau="niveau"
                          :tickangle="0"/>
                  </div>
                  <div class="col-sm-8">
                      <stacked-bar-chart-colors-multi v-if="load.graphFour"
                      :marginBottom="70"
                      :marginTop="40"
                      :title="'Leeftijdsverdeling door de tijd'"
                      :name4="'< 65 jaar'"
                      :x4="zorgkantoorregionaam_65ddt"
                      :xx4="jaar_65ddt"
                      :y4="leeftijd_65ddt"
                      :name3="'66 - 79 jaar'"
                      :x3="zorgkantoorregionaam_66ddt"
                      :xx3="jaar_66ddt"
                      :y3="leeftijd_66ddt"
                      :name2="'80 - 89 jaar'"
                      :x2="zorgkantoorregionaam_80ddt"
                      :xx2="jaar_80ddt"
                      :y2="leeftijd_80ddt"
                      :name1="'90 jaar en ouder'"
                      :x1="zorgkantoorregionaam_90ddt"
                      :xx1="jaar_90ddt"
                      :y1="leeftijd_90ddt"
                      :showlegend="false"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row down">
      <div class="col-sm-12">
        <card :cardId="'Inkomensverdeling mensen met dementie'" :fontsize="15" :hovertext="'Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'"
          :x="zorgkantoorregionaam_inkomen" :y="jaar_inkomen " :z="categorie_inkomen" 
          :w="percentage_inkomen.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Jaar'" :ztitle="'Categorie'"  :wtitle="'Percentage'" 
          :tableHeight="selectedLevel === 'dementienetwerk' ? 1000 : 900">
          <template slot="card-header">Inkomensverdeling mensen met dementie</template>
          <div slot="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="bigger">
                      Hiernaast worden de gegevens getoond over het gestandaardiseerd huishoudensinkomen van mensen met dementie in {{level_regio}} {{selectedRegion}}. Ook wordt de vergelijking met landelijke cijfers gemaakt. Let op dat het inkomen nog niet gecorrigeerd is voor inflatie.
                      <br> <br>
                      Let op dat het inkomen nog niet gecorrigeerd is voor inflatie, hierdoor zijn er mogelijk meer verschuivingen tussen de inkomensgroepen te zien. 
                    </div>
                  </div>
                  <div class="col-sm-9">
                      <stacked-bar-chart-colors-multi v-if="load.graphSeven"
                      :marginBottom="70"
                      :marginTop="40"
                      :title="'Inkomensverdeling door de tijd'"
                      :name1="'< €15.000'"
                      :x1="zorgkantoorregionaam_10k_ddt"
                      :xx1="jaar_10k_ddt"
                      :y1="inkomen_10k_ddt"
                      :name2="'€15.000-€20.000'"
                      :x2="zorgkantoorregionaam_20k_ddt"
                      :xx2="jaar_20k_ddt"
                      :y2="inkomen_20k_ddt"
                      :name3="'€20.000-€30.000'"
                      :x3="zorgkantoorregionaam_30k_ddt"
                      :xx3="jaar_30k_ddt"
                      :y3="inkomen_30k_ddt"
                      :name4="'€30.000 +'"
                      :x4="zorgkantoorregionaam_40k_ddt"
                      :xx4="jaar_40k_ddt"
                      :y4="inkomen_40k_ddt"
                      :showlegend="true"
                      :legendAnchor = "-0.2"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row down">
      <div class="col-sm-12">
        <card :cardId="'Gebruik Wmo-gefinancierde zorg'" :fontsize="15" :hovertext="'Deze informatie heeft alleen betrekking op de populatie bewoners van gemeenten die in het desbetreffende kalenderjaar deelnamen aan de Monitor Sociaal Domein en toestemming hebben gegeven om de data via CBS beschikbaar te stellen voor onderzoek. Het gaat hier dus om een deel van de totale populatie mensen met dementie. Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'" 
          :x="zorgkantoorregionaam_gebruik" :y="jaar_gebruik" :z="percentage_gebruik.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Jaar'" :ztitle="'Percentage'" 
          :tableHeight="selectedLevel === 'dementienetwerk' ? 500 : 400">
          <template slot="card-header">Gebruik Wmo-gefinancierde zorg</template>
          <div slot="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="bigger">
                      Voor de informatie met betrekking tot Wmo-zorg wordt uitsluitend gekeken naar de thuiswonende populatie die geen Wlz-zorg ontvangt. In zorgkantoorregio {{level_regio}} <b>{{selectedRegion}}</b> ontvangt in {{selectedYear}} <b>{{(perc_wmo[0]*100).toFixed(1)}}</b>% van hen Wmo-zorg.
                    </div>
                  </div>
                  <div class="col-sm-9">
                      <stacked-bar-chart-colors-multi v-if="load.graphFive"
                      :marginBottom="70"
                      :marginTop="40"
                      :ytitle="'% met Wmo'"
                      :title="'Percentage personen met Wmo-gefinancierde zorg onder thuiswonende mensen met dementie'"
                      :name1="'Percentage Wmo'"
                      :x1="regionaam_ddt"
                      :xx1="jaar_ddt"
                      :y1="perc_wmo_ddt"
                      :showlegend="false"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div v-if="selectedLevel==='zorgkantoor'" class="row down">
      <div class="col-sm-12">
        <card :cardId="'Gebruik Wlz-gefinancierde zorg'" :fontsize="15" :hovertext="'Op de Help-pagina is te lezen hoe personen zijn geïdentificeerd. De identificatie van personen met dementie in 2020 wijkt iets af van de andere jaren aangezien hier geen ziekenhuisdata is gebruikt om personen te identificeren.'" 
          :x="zorgkantoorregionaam_natura" :y="natura_jaar" :z="percentage_natura_table.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Jaar'" :ztitle="'Percentage'" 
          :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 400">
          <template slot="card-header">Gebruik Wlz-gefinancierde zorg</template>
          <div slot="card-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-3">
                    <div class="bigger">
                      Voor de informatie met betrekking tot Wlz-zorg wordt uitsluitend gekeken naar de mensen met dementie die Wlz-zorg ontvangen. In zorgkantoorregio {{level_regio}} <b>{{selectedRegion}}</b> ontvangt in {{selectedYear}} <b>{{(jaar_percentage[0]*100).toFixed(1)}}</b>% van hen Wlz-zorg met verblijf.
                    </div>
                  </div>
                  <div class="col-sm-9">
                      <stacked-bar-chart-colors-multi v-if="load.graphFive"
                      :marginBottom="70"
                      :marginTop="40"
                      :ytitle="'% met Wlz verblijf'"
                      :title="'Percentage personen dat Wlz met verblijf ontvangt onder personen met dementie die gebruik maken van Wlz-zorg'"
                      :name1="'Percentage Wlz'"
                      :x1="zorgkantoorregionaam_natura"
                      :xx1="natura_jaar"
                      :y1="percentage_natura"
                      :showlegend="false"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div>
      <p></p>
      <p>Ontwikkeld door ADC en ESHPM in opdracht van het ministerie van Volksgezondheid, Welzijn en Sport. Voor vragen of opmerkingen kunt u contact opnemen met <a class="custom-link" href="mailto:dementiedata@amsterdamdatacollective.com">dementiedata@amsterdamdatacollective.com</a>.</p>
    </div>

    <div class="row custom-margin">
      <div class="col-sm-6 center">
        <div class="item">
          <a href="https://www.eur.nl/eshpm/onderzoek/onderzoeksgroepen/health-economics/themas/ouderenzorg" target="_blank" rel="noopener">
            <img src="../assets/erasmuslogo.png" class="header-logo" height="50vh"/>
          </a>
        </div>
      </div>
      <div class="col-sm-6 center">
        <div class="item">
          <a href="https://amsterdamdatacollective.com/" target="_blank" rel="noopener">
            <img src="../assets/ADC full logo.png" class="header-logo" height="40vh"/>
          </a>
        </div>
      </div>
    </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
// import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsNumbers from '../components/StackedBarChartColorsNumbers'
import StackedBarChartColorsMulti from '../components/StackedBarChartColorsMulti'
import Card from '@/components/Card'
import CardSmall from '../components/CardSmall'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Mijn regio',
  },
  components: {StackedBarChartColorsMulti, StackedBarChartColorsNumbers, Card, CardSmall},
  data () {
    return {
      niveau:'',
      level_regio: '',
      geidentificeerd: 0,
      zorgkantoorregionaam_ep: [],
      zorgkantoorregionaam_mp: [],
      zorgkantoorregionaam_zi: [],
      eenpersoons: [],
      meerpersoons: [],
      zorginstelling: [],
      ddt_zorgkantoorregionaam_ep: [],
      ddt_zorgkantoorregionaam_mp: [],
      ddt_zorgkantoorregionaam_zi: [],
      ddt_eenpersoons: [],
      ddt_meerpersoons: [],
      ddt_zorginstelling: [],
      ddt_jaar_mp: [],
      ddt_jaar_ep: [],
      ddt_jaar_i: [],
      load: {
        graphOne:false,
        graphTwo:false,
        graphThree:false,
        graphFour: false,
        graphFive: false,
        graphSix: false,
        graphSeven: false,
        graphEight:false,
        graphNine: false,
        graphTen: false,
      },
      zorgkantoorregionaam_65: [],
      leeftijd_65: [],
      zorgkantoorregionaam_66: [],
      leeftijd_66: [],
      zorgkantoorregionaam_80: [],
      leeftijd_80: [],
      zorgkantoorregionaam_90: [],
      leeftijd_90: [],
      zorgkantoorregionaam_65ddt: [],
      leeftijd_65ddt: [],
      zorgkantoorregionaam_66ddt: [],
      leeftijd_66ddt: [],
      zorgkantoorregionaam_80ddt: [],
      leeftijd_80ddt: [],
      zorgkantoorregionaam_90ddt: [],
      leeftijd_90ddt: [],
      jaar_65ddt: [],
      jaar_66ddt: [],
      jaar_80ddt: [],
      jaar_90ddt: [],
      aantal_wmo: 0,
      aantal_subpopulatie_wmo: 0,
      perc_wmo: 0,
      perc_wmo_ddt: [],
      jaar_ddt: [],
      zorgkantoorregionaam_gebruik:[],
      jaar_gebruik :[],
      percentage_gebruik :[],
      regionaam_ddt: [],
      netwerken: [],
      verschil: [],
      netwerken_ddt: [],
      verschil_ddt: [],
      verschil_ddt_table: [],
      jaar_iden_ddt: [],
      zorgkantoorregionaam_10k_ddt: [],
      inkomen_10k_ddt: [],
      jaar_10k_ddt: [],
      zorgkantoorregionaam_20k_ddt: [],
      inkomen_20k_ddt: [],
      jaar_20k_ddt: [],
      zorgkantoorregionaam_30k_ddt: [],
      inkomen_30k_ddt: [],
      jaar_30k_ddt: [],
      zorgkantoorregionaam_40k_ddt: [],
      inkomen_40k_ddt: [],
      zorgkantoorregionaam_m40k_ddt: [],
      inkomen_m40k_ddt: [],
      jaar_40k_ddt: [],
      percentage_natura: [],
      percentage_natura_table: [],
      zorgkantoorregionaam_natura: [],
      natura_jaar: [],
      aantal_subpopulatie_wlz: 0,
      aantal_natura: 0,
      jaar_percentage: 0,
      jaar_landelijk: [],
      wlz_landelijk: [],
      ziekenhuis_landelijk: [],
      doodsoorzaak_landelijk: [],
      medicatie_landelijk: [],
      huisarts_landelijk: [],
      overig_landelijk: [],
      wlz_landelijk_perc: [],
      ziekenhuis_landelijk_perc: [],
      doodsoorzaak_landelijk_perc: [],
      medicatie_landelijk_perc: [],
      huisarts_landelijk_perc: [],
      overig_landelijk_perc: [],
      zorgkantoorregionaam_huishouden :[],
      jaar_huishouden :[],
      percentage_huishouden :[],
      zorgkantoorregionaam_leeftijd :[],
      aantal_leeftijd :[],
      jaar_leeftijd :[],
      categorie_leeftijd :[],
      percentage_leeftijd :[],
      zorgkantoorregionaam_inkomen:[],
      jaar_inkomen:[], 
      categorie_inkomen:[],
      percentage_inkomen:[],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
      this.changeLevel(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    changeLevel(level) {
      if (level === 'zorgkantoor') {
        this.level_regio = 'zorgkantoorregio'
      }
      else {
        this.level_regio= 'dementienetwerk'
      }
    },
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`api/zorgkantoor_totaal/?regio=${this.$store.state.selectedRegion}&jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.geidentificeerd = response.data.map(d => d.aantal)
          }
        ),
        this.$http.get(`/api/zorgkantoor_huishouden/get_data/?regio=${this.$store.state.selectedRegion}&jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Landelijk')
            this.zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.zorgkantoorregionaam)
            this.eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => d.aantal)
            this.zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.zorgkantoorregionaam)
            this.meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => d.aantal)
            this.zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.zorgkantoorregionaam)
            this.zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => d.aantal)
            this.niveau = ""
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_huishouden/get_data/?regio=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data.sort((a,b) => a.zorgkantoorregionaam-b.zorgkantoorregionaam)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.ddt_zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.zorgkantoorregionaam)
            this.ddt_jaar_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.jaar)
            this.ddt_eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.ddt_zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.zorgkantoorregionaam)
            this.ddt_meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.ddt_jaar_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.jaar)
            this.ddt_zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.zorgkantoorregionaam)
            this.ddt_zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.ddt_jaar_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.jaar)

            this.zorgkantoorregionaam_huishouden = response.data.map(d => d.zorgkantoorregionaam)
            this.jaar_huishouden = response.data.map(d => d.jaar)
            this.percentage_huishouden = response.data.map(d => (d.percentage*100).toFixed(1))

            this.load.graphTwo=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_leeftijd/get_data/?regio=${this.$store.state.selectedRegion}&jaar=${this.$store.state.selectedYear}&dementie=True`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Landelijk')
            this.zorgkantoorregionaam_65 = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.zorgkantoorregionaam)
            this.leeftijd_65 = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.aantal)
            this.zorgkantoorregionaam_66 = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.zorgkantoorregionaam)
            this.leeftijd_66 = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.aantal)
            this.zorgkantoorregionaam_80 = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.zorgkantoorregionaam)
            this.leeftijd_80 = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.aantal)
            this.zorgkantoorregionaam_90 = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.zorgkantoorregionaam)
            this.leeftijd_90 = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.aantal)
            this.niveau = ""

            this.zorgkantoorregionaam_leeftijd = response.data.map(d => d.zorgkantoorregionaam)
            this.aantal_leeftijd = response.data.map(d => d.aantal)
            this.jaar_leeftijd = response.data.map(d => d.jaar)
            this.categorie_leeftijd = response.data.map(x => x.leeftijdscategorie)
            this.percentage_leeftijd = response.data.map(x => (x.percentage*100).toFixed(1))


            this.load.graphThree=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_leeftijd/get_data/?regio=${this.$store.state.selectedRegion}&dementie=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data.sort((a,b) => a.zorgkantoorregionaam-b.zorgkantoorregionaam)
            this.zorgkantoorregionaam_65ddt = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.zorgkantoorregionaam)
            this.jaar_65ddt = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.jaar)
            this.leeftijd_65ddt = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => (d.percentage))
            this.zorgkantoorregionaam_66ddt = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.zorgkantoorregionaam)
            this.jaar_66ddt = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.jaar)
            this.leeftijd_66ddt = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => (d.percentage))
            this.zorgkantoorregionaam_80ddt = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.zorgkantoorregionaam)
            this.jaar_80ddt = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.jaar)
            this.leeftijd_80ddt = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => (d.percentage))
            this.zorgkantoorregionaam_90ddt = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.zorgkantoorregionaam)
            this.jaar_90ddt = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.jaar)
            this.leeftijd_90ddt = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => (d.percentage))
            this.load.graphFour=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_ondersteuning/get_data/?regio=${this.$store.state.selectedRegion}&jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Landelijk')
            this.aantal_subpopulatie_wmo = response.data.map(d => (d.subpopulatie_aantal))
            this.aantal_wmo = response.data.map(d => (d.wmo))
            this.aantal_hulphuis = response.data.map(d => (d.percentage_hulp_huishouden))
            this.aantal_hulpdienst = response.data.map(d => (d.percentage_hulpmiddelen_diensten))
            this.perc_wmo = response.data.map(d => (d.percentage_wmo))
            // this.perc_dagopvang = response.data.map(d => (d.percentage_dagopvang))
            // this.zorgkantoorregionaam = response.data.map(d => d.zorgkantoorregionaam)
            // this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_ondersteuning/get_data/?regio=${this.$store.state.selectedRegion}`).then(
          (response) => {
            // response.data = response.data.filter(x => x.dementienetwerknaamregionaam !=='Landelijk')
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data.sort((a,b) => a.zorgkantoorregionaam-b.zorgkantoorregionaam)
            this.perc_wmo_ddt = response.data.map(d => (d.percentage_wmo))
            this.regionaam_ddt = response.data.map(d => d.zorgkantoorregionaam)
            this.jaar_ddt = response.data.map(d => d.jaar)

            this.percentage_gebruik = response.data.map(d => (d.percentage_wmo*100).toFixed(1))
            this.zorgkantoorregionaam_gebruik = response.data.map(d => d.zorgkantoorregionaam)
            this.jaar_gebruik = response.data.map(d => d.jaar)

            this.load.graphFive=true
          }
        ),
        this.$http.get(`api/zorgkantoor_totaal/zorgkantoor/?regio=${this.$store.state.selectedRegion}`).then(
        (response) => {
          response.data.sort((a,b) => a.jaar-b.jaar)
          response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
          this.netwerken_ddt = response.data.map(d => d.zorgkantoorregionaam)
          this.verschil_ddt = response.data.map(d => (d.proportie))
          this.verschil_ddt_table = response.data.map(d => (d.proportie*100).toFixed(1))
          //this.jaar_iden_ddt = response.data.map(d => d.jaar)
          this.jaar_iden_ddt = response.data.map(d => d.jaar)
          this.load.graphSix=true
          //
          }
        ),
        this.$http.get(`/api/zorgkantoor_inkomen/get_data/?regio=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.zorgkantoorregionaam)
            this.inkomen_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.jaar)
            this.zorgkantoorregionaam_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.zorgkantoorregionaam)
            this.inkomen_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.jaar_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.jaar)
            this.zorgkantoorregionaam_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.zorgkantoorregionaam)
            this.inkomen_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.jaar_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.jaar)
            this.zorgkantoorregionaam_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.zorgkantoorregionaam)
            this.inkomen_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))
            this.jaar_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.jaar)

            this.zorgkantoorregionaam_inkomen = response.data.map(x => x.zorgkantoorregionaam)
            this.jaar_inkomen = response.data.map(x => x.jaar)
            this.percentage_inkomen = response.data.map(x => (x.percentage*100).toFixed(1))
            this.categorie_inkomen = response.data.map(x => x.inkomen)

            this.load.graphSeven=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_leveringsvorm/get_data/?regio=${this.$store.state.selectedRegion}`).then(
          (response) => {
            //response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data.sort((a,b) => a.zorgkantoorregionaam-b.zorgkantoorregionaam)
            this.percentage_natura = response.data.map(d => (d.percentage_natura))
            this.percentage_natura_table = response.data.map(d => (d.percentage_natura*100).toFixed(1))
            this.zorgkantoorregionaam_natura = response.data.map(d => d.zorgkantoorregionaam)
            this.natura_jaar = response.data.map(d => d.jaar)
            this.aantal_subpopulatie_wlz = response.data.filter(x => x.jaar == this.$store.state.selectedYear).map(d => (d.subpopulatie_aantal))
            this.aantal_natura = response.data.filter(x => (x.jaar == this.$store.state.selectedYear)).map(d => (d.natura))
            this.jaar_percentage = response.data.filter(x => x.jaar == this.$store.state.selectedYear).map(d => (d.percentage_natura))
            this.load.graphFive=true
          }
        )
        this.$http.get(`api/uitsplitsingidentificatie_zk/get_data/?landelijk=true`).then(
          (response) => {
            this.jaar_landelijk = response.data.map(d => d.jaar)
            this.wlz_landelijk = response.data.map(d => (d.wlz))
            this.ziekenhuis_landelijk = response.data.map(d => (d.ziekenhuis))
            this.doodsoorzaak_landelijk = response.data.map(d => (d.doodsoorzaak))
            this.medicatie_landelijk = response.data.map(d => (d.medicatie))
            this.huisarts_landelijk = response.data.map(d => (d.huisarts))
            this.overig_landelijk = response.data.map(d => (d.overig))
            this.load.graphNine=true
          }
        )
        this.$http.get(`api/uitsplitsingidentificatie_zk/get_data/?landelijk=true`).then(
          (response) => {
            this.jaar_landelijk = response.data.map(d => d.jaar)
            this.wlz_landelijk_perc = response.data.map(d => d.wlz_perc*100)
            this.ziekenhuis_landelijk_perc = response.data.map(d => d.ziekenhuis_perc*100)
            this.doodsoorzaak_landelijk_perc = response.data.map(d => d.doodsoorzaak_perc*100)
            this.medicatie_landelijk_perc = response.data.map(d => d.medicatie_perc*100)
            this.huisarts_landelijk_perc = response.data.map(d => d.huisarts_perc*100)
            this.overig_landelijk_perc = response.data.map(d => d.overig_perc*100)
            this.load.graphTen=true
          }
        )
      }
      else {
        this.$http.get(`api/dementienetwerk_totaal/?netwerk=${this.$store.state.selectedRegion}&jaar=${this.$store.state.selectedYear}`).then(
        (response) => {
          this.geidentificeerd = response.data.map(d => d.aantal)
          }
        ),
        this.$http.get(`/api/dementienetwerk_huishouden/get_data/?netwerk=${this.$store.state.selectedRegion}&jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            this.load.graphOne=false
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Landelijk')
            this.zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.dementienetwerknaam)
            this.eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => d.aantal)
            this.zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.dementienetwerknaam)
            this.meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => d.aantal)
            this.zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.dementienetwerknaam)
            this.zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => d.aantal)
            this.niveau = "dementienetwerk"
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_huishouden/get_data/?netwerk=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data.sort((a,b) => a.dementienetwerknaam-b.dementienetwerknaam)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.ddt_zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.dementienetwerknaam)
            this.ddt_jaar_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.jaar)
            this.ddt_eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.ddt_zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.dementienetwerknaam)
            this.ddt_meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.ddt_jaar_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.jaar)
            this.ddt_zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.dementienetwerknaam)
            this.ddt_zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.ddt_jaar_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.jaar)

            this.zorgkantoorregionaam_huishouden = response.data.map(d => d.dementienetwerknaam)
            this.jaar_huishouden = response.data.map(d => d.jaar)
            this.percentage_huishouden = response.data.map(d => (d.percentage*100).toFixed(1))
            this.load.graphTwo=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_leeftijd/get_data/?netwerk=${this.$store.state.selectedRegion}&jaar=${this.$store.state.selectedYear}&dementie=True`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Landelijk')
            this.zorgkantoorregionaam_65 = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.dementienetwerknaam)
            this.leeftijd_65 = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.aantal)
            this.zorgkantoorregionaam_66 = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.dementienetwerknaam)
            this.leeftijd_66 = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.aantal)
            this.zorgkantoorregionaam_80 = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.dementienetwerknaam)
            this.leeftijd_80 = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.aantal)
            this.zorgkantoorregionaam_90 = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.dementienetwerknaam)
            this.leeftijd_90 = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.aantal)
            this.niveau = "dementienetwerk"

            this.zorgkantoorregionaam_leeftijd = response.data.map(d => d.dementienetwerknaam)
            this.aantal_leeftijd = response.data.map(d => d.aantal)
            this.jaar_leeftijd = response.data.map(d => d.jaar)
            this.categorie_leeftijd = response.data.map(x => x.leeftijdscategorie)
            this.percentage_leeftijd = response.data.map(x => (x.percentage*100).toFixed(1))

            this.load.graphThree=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_leeftijd/get_data/?netwerk=${this.$store.state.selectedRegion}&dementie=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data.sort((a,b) => a.dementienetwerknaam-b.dementienetwerknaam)
            this.zorgkantoorregionaam_65ddt = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.dementienetwerknaam)
            this.jaar_65ddt = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.jaar)
            this.leeftijd_65ddt = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => (d.percentage))
            this.zorgkantoorregionaam_66ddt = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.dementienetwerknaam)
            this.jaar_66ddt = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.jaar)
            this.leeftijd_66ddt = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => (d.percentage))
            this.zorgkantoorregionaam_80ddt = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.dementienetwerknaam)
            this.jaar_80ddt = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.jaar)
            this.leeftijd_80ddt = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => (d.percentage))
            this.zorgkantoorregionaam_90ddt = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.dementienetwerknaam)
            this.jaar_90ddt = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.jaar)
            this.leeftijd_90ddt = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => (d.percentage))
            this.load.graphFour=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_ondersteuning/get_data/?netwerk=${this.$store.state.selectedRegion}&jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            // response.data = response.data.filter(x => x.dementienetwerknaamregionaam !=='Landelijk')
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Landelijk')
            this.aantal_subpopulatie_wmo = response.data.map(d => (d.subpopulatie_aantal))
            this.aantal_wmo = response.data.map(d => (d.wmo))
            this.perc_wmo = response.data.map(d => (d.percentage_wmo))
            this.aantal_hulphuis = response.data.map(d => (d.percentage_hulp_huishouden))
            this.aantal_hulpdienst = response.data.map(d => (d.percentage_hulpmiddelen_diensten)) 

          }
        ),
        this.$http.get(`/api/dementienetwerk_ondersteuning/get_data/?netwerk=${this.$store.state.selectedRegion}`).then(
          (response) => {
            // response.data = response.data.filter(x => x.dementienetwerknaamregionaam !=='Landelijk')
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data.sort((a,b) => a.dementienetwerknaam-b.dementienetwerknaam)
            this.perc_wmo_ddt = response.data.map(d => (d.percentage_wmo))
            this.regionaam_ddt = response.data.map(d => d.dementienetwerknaam)
            this.jaar_ddt = response.data.map(d => d.jaar)

            this.percentage_gebruik = response.data.map(d => (d.percentage_wmo*100).toFixed(1))
            this.zorgkantoorregionaam_gebruik = response.data.map(d => d.dementienetwerknaam)
            this.jaar_gebruik = response.data.map(d => d.jaar)
            this.load.graphFive=true
          }
        ),
        this.$http.get(`api/dementienetwerk_totaal/dementienetwerk_leeftijd/?netwerk=${this.$store.state.selectedRegion}`).then(
        (response) => {
          response.data.sort((a,b) => a.jaar-b.jaar)
          this.netwerken_ddt = response.data.map(d => d.dementienetwerknaam)
          this.verschil_ddt = response.data.map(d => (d.proportie))
          this.verschil_ddt_table = response.data.map(d => (d.proportie*100).toFixed(1))
          this.jaar_iden_ddt = response.data.map(d => d.jaar)
          this.load.graphSix=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_inkomen/get_data/?netwerk=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.zorgkantoorregionaam_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.dementienetwerknaam)
            this.inkomen_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='0-14999').map(d => d.jaar)
            this.zorgkantoorregionaam_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.dementienetwerknaam)
            this.inkomen_20k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.jaar)
            this.zorgkantoorregionaam_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.dementienetwerknaam)
            this.inkomen_30k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.jaar)
            this.zorgkantoorregionaam_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.dementienetwerknaam)
            this.inkomen_40k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))
            this.jaar_10k_ddt = response.data.filter(x => x.inkomen =='30000+').map(d => d.jaar)

            this.zorgkantoorregionaam_inkomen = response.data.map(x => x.dementienetwerknaam)
            this.jaar_inkomen = response.data.map(x => x.jaar)
            this.percentage_inkomen = response.data.map(x => (x.percentage*100).toFixed(1))
            this.categorie_inkomen = response.data.map(x => x.inkomen)
            this.load.graphSeven=true
          }
        )
      }
    }
  },
  mounted () {
        this.loadAllData(this.selectedLevel),
        this.changeLevel(this.selectedLevel)
      }
}

</script>

<style scoped>
.col-sm-4 {
  text-align: left;
  }

.bigger {
  font-size: 8;
}

.left {
  text-align: left;
}
.align-self-center {
  margin-top: -80px;
}

.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}
</style>
